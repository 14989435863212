export const COLUMNS = [
    {
        Header: 'Resource',
        accessor: 'name'
    },
    {
        Header: 'Environment',
        accessor: 'enviroment_c'
    },
    {
        Header: 'IP',
        accessor: 'ip_address_c'
    },
    {
        Header: 'URI',
        accessor: 'url_c'
    },
]
export const TASKS = [
    {
        Header: 'Board',
        accessor: 'boardName'
    },
    {
        Header: 'List',
        accessor: 'listName'
    },
    {
        Header: 'Task',
        accessor: 'name'
    },
    {
        Header: 'Description',
        accessor: 'desc'
    },
    {
        Header: 'Labels',
        accessor: 'labels',
        Cell: ({ value }) => {
            const labelColor = {
                "red":"#EB5A46",
                "lime":"#51E898",
                "orange":"#FF9F1A",
                "pink":"#FF78CB",
                "yellow":"#F2D600",
                "sky":"#00C2E0",
                "blue":"#0079BF",
                "green":"#61BD4F",
                "purple":"#00C2E0",
                "black":"#344563",
                "red_light":"#ef7564",
                "lime_light":"#b3f1d0",
                "orange_light":"#fad29c",
                "pink_light":"#f9c2e4",
                "yellow_light":"#f5ea92",
                "sky_light":"#8fdfeb",
                "blue_light":"#8bbdd9",
                "green_light":"#b7ddb0",
                "purple_light":"#dfc0eb",
                "black_light":"#505f79",
                "red_dark":"#cf513d",
                "lime_dark":"#4ed583",
                "orange_dark":"#e79217",
                "pink_dark":"#e568af",
                "yellow_dark":"#e6c60d",
                "sky_dark":"#00aecc",
                "blue_dark":"#026aa7",
                "green_dark":"#5aac44",
                "purple_dark":"#a86cc1",
                "black_dark":"#091e42"
            }
            return (
                <div className="label_wrapper">{value.map((label) => {
                    let bgcolor = '';
                    if(!labelColor[label.color])
                        bgcolor = "#00C2E0";
                    else
                        bgcolor = labelColor[label.color];
                    return (
                        <div className="label_single" style={{backgroundColor:bgcolor}}>{label.name}</div>
                    );
                })}
                </div>
            )
        }
    },
]